<template>
  <div class="signup-box">
    <transition-group v-if="!isPhone()" name="list" tag="ul" ref="container">
      <li
        v-for="(item, key) in data"
        :key="key"
        class="list-item"
        v-show="item.id === currentIndex"
      >
        <div class="signup-content">
          <span
            class="tips-image"
            :style="'background-image: url(' + item.image + ')'"
          ></span>
          <div class="tips-content">
            <div class="box">
              <p class="tips-text" v-html="item.text"></p>
            </div>
            <div class="tips-btn">
              <a href="javascript:" @click="onRegister">{{
                $t("carousel.signUp")
              }}</a>
              /
              <a href="javascript:" @click="onSignIn" class="arrow">{{
                $t("carousel.login")
              }}</a>
            </div>
          </div>
        </div>
      </li>
    </transition-group>
    <!-- s: circles -->
    <div class="circles-items" v-if="!isPhone()">
      <a
        href="javascript:"
        class="circles-l circles-arrow"
        @click="onSwitchLeft()"
      ></a>
      <a
        class="circles-n"
        :key="index"
        v-for="index in data.length"
        :class="{ active: index === currentIndex }"
      ></a>
      <a
        href="javascript:"
        class="circles-r circles-arrow"
        @click="onSwitchRight()"
      ></a>
    </div>
    <!-- e: circles -->

    <van-swipe
      v-if="isPhone()"
      class="my-swipe"
      :autoplay="3000"
      indicator-color="#dcdcdc"
    >
      <van-swipe-item v-for="(item, key) in data" :key="key">
        <div class="signup-content">
          <span
            class="tips-image"
            :style="'background-image: url(' + item.image + ')'"
          ></span>
          <div class="tips-content">
            <div class="box">
              <p class="tips-text" v-html="item.text"></p>
            </div>
            <div class="tips-btn">
              <a href="javascript:" @click="onRegister">{{
                $t("carousel.signUp")
              }}</a>
              /
              <a href="javascript:" @click="onSignIn" class="arrow">{{
                $t("carousel.login")
              }}</a>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import SignupRotation from "./signupRotation";
export default SignupRotation;
</script>
<style lang="less">
@import "signupRotation";
</style>

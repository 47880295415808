<template>
  <div class="layout-home-page">
    <!--s: Banner-->
    <div class="layout-banner">
      <Banner :data="bannerList" v-if="!isPhone()" />
      <van-swipe
        v-if="isPhone()"
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item :key="key" v-for="(banner, key) in bannerList">
          <div class="banner-box">
            <div
              class="banner-image"
              v-lazy:background-image="banner.coverImage"
            ></div>
            <div class="box">
              <h1 class="banner-title">{{ banner.coverTitle }}</h1>
              <p class="banner-text">- {{ banner.coverSubtitle }} -</p>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!--e: Banner-->

    <!--s: title-->
    <div class="layout-title">
      <h1 class="title-name">
        {{ $t("headline.superSale") }}
      </h1>
      <div class="title-box">
        <a
          class="title-link"
          href="javascript:"
          @click="onProductList(superSaleObj, $t('headline.superSale'))"
          >{{ $t("headline.more") }}<i class="icon"></i
        ></a>
      </div>
    </div>
    <!--e: title-->

    <!--s: Super sale-->
    <div class="layout-super-sale">
      <div class="loading" v-if="loadingStatus"></div>
      <Carousel
        :data="superSaleObj"
        @on-links="onCarousel"
        @on-like="onLike"
        @on-links-list="onProductList($event, $t('headline.superSale'))"
      />
    </div>
    <!--s: Super sale-->

    <!--s: Recommend-->
    <div class="layout-recommend">
      <ul>
        <li :key="key" v-for="(recommend, key) in recommendList">
          <img
            @click="onProductList(recommend)"
            :src="recommend.coverImage"
            v-lazy="recommend.coverImage"
          />
          <h3 class="recommend-name">{{ recommend.coverTitle }}</h3>
        </li>
      </ul>
    </div>
    <!--e: Recommend-->

    <!--s: title-->
    <div class="layout-title">
      <h1 class="title-name">
        {{ $t("headline.discover") }}
      </h1>
      <div class="title-box">
        <a
          class="title-link"
          href="javascript:"
          @click="$router.push('/discover/list')"
          >{{ $t("headline.more") }}<i class="icon"></i
        ></a>
      </div>
    </div>
    <!--e: title-->

    <!--s: Discover-->
    <div class="layout-product-list">
      <ul>
        <li
          :key="key"
          v-for="(discover, key) in discoverList"
          @click="onImageText(discover)"
        >
          <img
            class="discover-img"
            :src="discover.coverImage"
            v-lazy="discover.coverImage"
          />
          <div class="discover-text">
            <p
              class="text-row"
              :style="
                isPhone()
                  ? ''
                  : 'font-size:' +
                    discover.ctSize +
                    'px;line-height:' +
                    discover.ctLeading +
                    'px;text-align:' +
                    discover.ctLocation +
                    ';color:#' +
                    discover.ctColour
              "
            >
              {{ discover.coverTitle }}
            </p>
            <p class="text-title">{{ discover.keywords }}</p>
            <p
              v-if="!isPhone()"
              class="text-row"
              :style="
                'font-size:' +
                discover.csSize +
                'px;line-height:' +
                discover.csLeading +
                'px;text-align:' +
                discover.csLocation +
                ';color:#' +
                discover.csColour
              "
            >
              {{ discover.coverSubtitle }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <!--e: Discover-->

    <!--s: title-->
    <div class="layout-title" v-if="searchType !== 3 && searchType !== 4">
      <h1 class="title-name">
        {{ $t("headline.brandWeekly") }}
      </h1>
      <div class="title-box">
        <a
          class="title-link"
          href="javascript:"
          @click="onProductList(brandWeeklyList, $t('headline.brandWeekly'))"
          >{{ $t("headline.more") }}<i class="icon"></i
        ></a>
      </div>
    </div>
    <!--e: title-->

    <!--s: Brand Weekly-->
    <div
      class="layout-brand"
      id="brandId"
      v-if="searchType !== 3 && searchType !== 4"
    >
      <div class="loading" v-if="brandWeeklyLoading"></div>
      <div class="brand-img" id="brandImg">
        <img
          @click="onProductList(brandWeeklyList, $t('headline.brandWeekly'))"
          :src="brandWeeklyList.coverImage"
          v-lazy="brandWeeklyList.coverImage"
        />
        <h3 class="brand-name">{{ brandWeeklyList.brandName }}</h3>
      </div>

      <div class="brand-list" id="brandListId">
        <ul
          id="brandListUl"
          v-if="
            brandWeeklyList.productList &&
            brandWeeklyList.productList.length !== 0
          "
          :style="
            isPhone()
              ? 'width:' +
                (brandWeeklyList.productList.length * 5.3 + 4.1) +
                'rem'
              : ''
          "
        >
          <li
            :key="key"
            v-for="(brandWeekly, key) in brandWeeklyList.productList"
            ref="brandLi"
            :style="
              key + 1 === brandWeeklyList.productList.length
                ? 'margin-bottom:0'
                : ''
            "
          >
            <div class="list-handle">
              <a
                href="javascriptonProductList:"
                class="handle-unified like"
                @click="onLike(brandWeekly)"
                :class="brandWeekly.isWish === 1 ? 'like' : 'like-grey'"
              ></a>
              <a
                href="javascript:"
                class="handle-unified star"
                v-if="brandWeekly.isStar === 1"
              ></a>
              <a
                href="javascript:"
                class="handle-unified draft"
                v-if="brandWeekly.isSole === 1"
              ></a>
            </div>
            <img
              class="carousel-image"
              @click="onCarousel(brandWeekly.id)"
              :src="brandWeekly.images"
              v-lazy="brandWeekly.images"
            />
            <div class="carouse-text">
              <p class="carouse-content brand">{{ brandWeekly.name }}</p>
              <p class="carouse-content">
                {{
                  currencyCode === "cny"
                    ? onCharacterPrice(brandWeekly.price)
                    : brandWeekly.price
                }}
              </p>
            </div>
          </li>
          <li class="list-more">
            <div
              class="more-box"
              @click="
                onProductList(brandWeeklyList, $t('headline.brandWeekly'))
              "
            >
              <div class="more-name">{{ brandWeeklyList.more }}</div>
              <div class="more-text" v-html="brandWeeklyList.ending"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--e: Brand Weekly-->

    <!--s: title-->
    <div class="layout-title" id="characterId" v-if="searchType !== 3 && searchType !== 4">
      <h1 class="title-name">
        {{ $t("headline.featured") }}
      </h1>
      <div class="title-box">
        <a
          class="title-link"
          href="javascript:"
          @click="$router.push('/featured/list')"
          >{{ $t("headline.more") }}<i class="icon"></i
        ></a>
      </div>
    </div>
    <!--e: title-->

    <!--s: characteristic-->
    <div class="layout-product-list characteristic-li" v-if="searchType !== 3 && searchType !== 4">
      <ul>
        <li
          :key="key"
          v-for="(featured, key) in featuredList"
          @click="onImageText(featured)"
        >
          <img
            class="discover-img"
            :src="featured.coverImage"
            v-lazy="featured.coverImage"
          />
          <div class="discover-text">
            <p
              class="text-row"
              :style="
                isPhone()
                  ? ''
                  : 'font-size:' +
                    featured.ctSize +
                    'px;line-height:' +
                    featured.ctLeading +
                    'px;text-align:' +
                    featured.ctLocation +
                    ';color:#' +
                    featured.ctColour
              "
            >
              {{ featured.coverTitle }}
            </p>
            <p
              class="text-title"
              :style="
                'font-size:' +
                featured.kwSize +
                'px;line-height:' +
                featured.kwLeading +
                'px;text-align:' +
                featured.kwLocation +
                ';color:#' +
                featured.kwColour
              "
            >
              {{ featured.keywords }}
            </p>
            <p
              v-if="!isPhone()"
              class="text-row"
              :style="
                'font-size:' +
                featured.csSize +
                'px;line-height:' +
                featured.csLeading +
                'px;text-align:' +
                featured.csLocation +
                ';color:#' +
                featured.csColour
              "
            >
              {{ featured.coverSubtitle }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <!--e: characteristic-->

    <!--s: title-->
    <div class="layout-title">
      <h1 class="title-name">{{ $t("headline.registeredMember") }}</h1>
    </div>
    <!--e: title-->

    <!--s: Signup for-->
    <div class="layout-signup-for">
      <div class="signup-content">
        <!--s: Left-->
        <div class="layout-signup-left">
          <SignupRotation :data="tipsData" />
        </div>
        <!--e: Left-->

        <!--s: Right-->
        <div class="layout-signup-right">
          <div class="signup-content-box" v-if="!subscribeStatus">
            <span class="tips" v-if="emailTipsStatus && !isPhone()">{{
              $t("login.emailTips")
            }}</span>
            <div class="signup-email">
              <input
                type="text"
                v-model="account"
                class="input"
                @focus="onFocus"
                @blur="onBlur"
                :placeholder="accountPlaceholder"
              />
            </div>
            <div class="signup-label">
              <div class="box">
                <ul>
                  <li
                    :key="key"
                    v-for="(subscribe, key) in subscribeType"
                    @click="onSignupCheck(subscribe.value)"
                    :class="langCode === 'CN' ? 'label-cn': 
                    subscribe.label === 'Women' ? 'label-223' : 
                    subscribe.label === 'Men' ? 'label-146' : 
                    subscribe.label === 'Kids'? 'label-143' : 'label-182'"
                  >
                    <i
                      class="label-icon"
                      :class="subscribe.checkBox ? 'select' : 'selected'"
                    ></i>
                    <span
                      class="label-name"
                      :class="subscribe.checkBox ? 'title' : ''"
                      :style="isPhone() ? '' : onSubStyle(subscribe.value)"
                      >{{ subscribe.label }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="signup-text" v-html="$t('signup.textTips')"></div>
            <Button
              class="pc-btn"
              :loading="loadingStatus"
              @click="onSignupSubmit"
            >
              <span v-if="!loadingStatus">{{ $t("signup.btn") }}</span>
              <span v-else>{{ $t("signup.btn") }}</span>
            </Button>
            <van-button
              class="h5-btn"
              :loading="loadingStatus"
              type="info"
              @click="onSignupSubmit"
            >
              <span v-if="!loadingStatus">{{ $t("signup.btn") }}</span>
              <span v-else>{{ $t("signup.btn") }}</span>
            </van-button>
          </div>
          <div class="signup-content-box signup-flax" v-if="subscribeStatus">
            <div class="signup-tips">
              <span class="tips-image"></span>
              <div class="tips-content">
                <div class="box">
                  <h2 class="tips-title">{{ $t("signup.thanks") }}</h2>
                  <p class="tips-text">{{ $t("signup.submitText") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--e: Right-->
      </div>
    </div>
    <!--e: Signup for-->
  </div>
</template>
<script>
import HomePage from "./homePage";
export default HomePage;
</script>
<style lang="less">
@import "homePage";
</style>

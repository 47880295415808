import Vue from "vue";
import { Button } from "iview";
import { Swipe, SwipeItem, Lazyload, Button as VanButton } from "vant";
Vue.component("Button", Button);
Vue.component("VanButton", VanButton);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
import Banner from "@/components/Banner/banner.vue";
import Carousel from "@/components/Carousel/carousel.vue";
import SignupRotation from "@/components/SignupRotation/signupRotation.vue";
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";
import {
  getBannerService,
  getMarketSuperSaleService,
  getMarketSelectedListService,
  getMarketDiscoverListService,
  getMarketBrandWeeklyListService,
  getMarketFeaturedListService
} from "@/service/market-service";
import { addGoodsCollectionService } from "@/service/goods-collection-service";
import { userAccountSubscribeService } from "@/service/user-service";
import { SOURCE_NAME, tipsData, subscribeType } from "@/service/public/enum";
import { onCharacterPrice, validateEmail, isPhone } from "@/utils/tools";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
const HomePage = {
  name: "HomePage",
  components: {
    Banner,
    Carousel,
    SignupRotation
  },
  computed: {
    ...mapState({
      currencyCode: (state) => state.system.currencyCode, //获取状态管理币种代码
      language: (state) => state.language.code, //获取状态语言
      menuType: (state) => state.product.menuType || localStorage.getItem('cid'), //获取状态管理菜单分类
      offsetTop: (state) => state.system.offsetTop, //获取状态管理滚动条高度
      langCode: state => state.language.code, //获取状态管理语言代码
    }),
    onSubStyle() {
      return (value) => {
        let style = "";
        if (value === 1) {
          style = "width:50px";
        }
        if (value === 2) {
          style = "width:28px";
        }
        if (value === 3) {
          style = "width:27px";
        }
        if (value === 4) {
          style = "width:38px";
        }
        return style;
      };
    }
  },
  data() {
    return {
      onCharacterPrice: onCharacterPrice,
      loadingStatus: false,
      brandWeeklyLoading: false,
      superSaleObj: {}, //特惠数据对象
      recommendList: [], //精选数据对象
      discoverList: [], //发现数据对象
      brandWeeklyList: {}, //品牌周刊数据对象
      featuredList: [], //特色数据对象
      bannerList: [], //banner数据对象
      sourceName: SOURCE_NAME,
      subscribeType: subscribeType, //订阅分类数据
      emailTipsStatus: false,
      account: "",
      subscribeStatus: false, //订阅状态
      tipsData: tipsData, //底部轮播数据
      classifySelect: "1", //选择分类
      accountPlaceholder: this.$t("login.account"),
      searchType: 1,
      isPhone: isPhone
    };
  },
  created() {
    /**
     * 订阅根据分类查询首页各项数据事件
     */
    $event.$on(EVENT_NAME.HOME_PAGE_CLASSIFY, () => {
      this.loadingStatus = true;
      this.onSuperSaleList().then();
      this.onBannerList().then();
      this.onDiscoverList().then();
      this.brandWeeklyLoading = true;
      this.onBrandWeeklyList().then();
      this.onFeaturedList().then();
      this.searchType = this.menuType;
    });
    this.searchType = this.menuType;
    this.loadingStatus = true;
    this.onSuperSaleList().then();
    this.onBannerList().then();
    this.onSelectedList().then();
    this.onDiscoverList().then();
    this.brandWeeklyLoading = true;
    this.onBrandWeeklyList().then();
    this.onFeaturedList().then();
  },
  mounted() {
    if (!this.isPhone()) {
      document.documentElement.scrollTop = document.body.scrollTop =
      this.offsetTop;
      document.addEventListener("mousewheel", this.handleScroll);
      document.addEventListener("wheel", this.handleScroll);
      document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
      this.handleScroll({deltaY: 1})
    }
  },
  methods: {
    ...mapMutations("product", [
      "SET_PRODUCT_ID",
      "SET_LABEL",
      "SET_MENU_ID",
      "SET_SELECTION_ID",
      "SET_SOURCE",
      "SET_SECOND_TYPE",
      "SET_TERTIARY_TYPE"
    ]),
    ...mapMutations("system", ["SET_OFFSET_TOP"]),
    handleScroll(e) {
      if (this.searchType === 3 || this.searchType === 4) {
        return;
      }
      let brandTop = document.getElementById("brandId").offsetTop;
      let brandListId = document.getElementById("brandListId");
      let brandListUl = document.getElementById("brandListUl");

      let bodyTop =
        Math.round(document.documentElement.scrollTop) || Math.round(document.body.scrollTop);

      if (e.deltaY > 0 && bodyTop >= brandTop) {
        if (
          brandListUl && brandListId &&
          Math.ceil(brandListId.scrollTop) >=
          brandListUl.offsetHeight - brandListId.offsetHeight
        ) {
          document.body.style.overflow = "visible";
        } else {
          document.body.style.overflow = "hidden";
          document.documentElement.scrollTop = document.body.scrollTop =
            brandTop;
          brandListId.scrollTop += e.deltaY;
        }
      } else {
        if (brandListId.scrollTop <= 0) {
          document.body.style.overflow = "visible";
        } else {
          if (bodyTop <= brandTop) {
            document.body.style.overflow = "hidden";
            document.documentElement.scrollTop = document.body.scrollTop =
              brandTop;
            brandListId.scrollTop += e.deltaY;
          }
        }
      }


      // if(bodyTop>=brandTop && bodyTop<=document.getElementById('characterId').offsetTop-brandListId.offsetHeight){
      //     document.body.style.overflow = "hidden";
      //     document.documentElement.scrollTop = document.body.scrollTop = brandTop
      //     brandListId.scrollTop += e.deltaY
      //     if(brandListId.scrollTop>=brandListUl.offsetHeight-brandListId.offsetHeight || brandListId.scrollTop === 0){
      //         document.body.style.overflow = "visible";
      //     }
      // }else{
      //     document.body.style.overflow = "visible";
      // }
    },
    onFocus() {
      this.accountPlaceholder = "";
    },
    onBlur() {
      this.accountPlaceholder = this.$t("login.account");
    },
    /**
     * 选中订阅
     */
    onSignupCheck(id) {
      for (let i in subscribeType) {
        if (id === subscribeType[i].value) {
          subscribeType[i].checkBox
            ? (subscribeType[i].checkBox = 0)
            : (subscribeType[i].checkBox = 1);
        }
      }
    },
    /**
     * 提交选中订阅
     */
    onSignupSubmit() {
      if (!validateEmail(this.account)) {
        this.emailTipsStatus = true;
        return;
      }
      this.emailTipsStatus = false;
      this.onAccountSubscribe().then();
    },
    /**
     * 跳转超卖详情页
     * @param id
     */
    onCarousel(id) {
      this.SET_PRODUCT_ID(id);
      this.$router.push("/product/details?id=" + id);
    },
    /**
     * 点击喜爱
     */
    onLike(obj) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      this.onGoodsCollection(obj.id, obj.isWish).then();
    },
    /**
     * 跳转列表页
     */
    onProductList(obj, name) {
      if (JSON.stringify(obj) === "{}") {
        return;
      }
      let secondId = "";
      let secondName = "";
      let tertiaryId = "";
      let tertiaryName = "";
      let label = "";
      let menuType = "";
      this.SET_LABEL(name);
      label = name;
      this.SET_SELECTION_ID(obj.selectionId);
      this.SET_MENU_ID(obj.id);
      menuType = obj.id;
      this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
      this.SET_SECOND_TYPE({
        categoryId: "",
        name: ""
      });
      secondId = "";
      secondName = "";
      this.SET_TERTIARY_TYPE({
        categoryId: "",
        name: ""
      });
      tertiaryId = "";
      tertiaryName = "";
      this.$router.push(
        "/product/list?menuType=" +
          menuType +
          "&secondId=" +
          secondId +
          "&secondName=" +
          secondName +
          "&tertiaryId=" +
          tertiaryId +
          "&tertiaryName=" +
          tertiaryName +
          "&label=" +
          label +
          "&page=" +
          1
      );
    },
    /**
     * 跳转图文
     * @param obj
     */
    onImageText(obj) {
      if (obj.contentType === 3) {
        this.SET_SELECTION_ID(obj.selectionId);
        this.SET_MENU_ID(obj.id);
        this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
        this.SET_SECOND_TYPE({
          categoryId: "",
          name: ""
        });
        this.SET_TERTIARY_TYPE({
          categoryId: "",
          name: ""
        });
        this.SET_LABEL(obj.coverTitle);
        this.$router.push("/product/list");
      } else {
        this.SET_PRODUCT_ID(obj.contentId);
        this.$router.push({ path: "/image/text?id=" + obj.contentId });
      }
    },
    /**
     * 拉取超卖列表
     * @returns {Promise<void>}
     */
    async onSuperSaleList() {
      try {
        let params = {
          cid1: this.menuType,
          size: 20,
          page: 1,
          status: 0
        };
        const data = await getMarketSuperSaleService(params);
        this.superSaleObj = data[0];
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    },
    /**
     * 拉取banner列表
     * @returns {Promise<void>}
     */
    async onBannerList() {
      try {
        let params = {
          categoryId: this.menuType,
          type: 1
        };
        const data = await getBannerService(params);
        this.bannerList = data;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 拉取首页精选
     * @returns {Promise<void>}
     */
    async onSelectedList() {
      try {
        const data = await getMarketSelectedListService();
        this.recommendList = data;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 拉取首页发现
     * @returns {Promise<void>}
     */
    async onDiscoverList() {
      try {
        let params = {
          cid1: this.menuType
        };
        const data = await getMarketDiscoverListService(params);
        this.discoverList = data;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 拉取首页品牌周刊
     * @returns {Promise<void>}
     */
    async onBrandWeeklyList() {
      try {
        let params = {
          cid1: this.menuType
        };
        const data = await getMarketBrandWeeklyListService(params);
        if (data) {
          this.brandWeeklyList = data[0];
          this.brandWeeklyList.productList =
          this.brandWeeklyList.productList.slice(0, 15);
        } else {
          this.brandWeeklyList.productList = [];
        }
        this.brandWeeklyLoading = false;
      } catch (error) {
        this.brandWeeklyLoading = false;
        console.log(error);
      }
    },
    /**
     * 拉取首页特色
     * @returns {Promise<void>}
     */
    async onFeaturedList() {
      try {
        let params = {
          cid1: this.menuType
        };
        const data = await getMarketFeaturedListService(params);
        this.featuredList = data;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 新增商品收藏
     * @returns {Promise<void>}
     */
    async onGoodsCollection(id, type) {
      try {
        let params = {
          productId: id,
          type: type === 0 ? 1 : 0
        };
        const { code } = await addGoodsCollectionService(params);
        if (code === 1) {
          for (let i in this.superSaleObj.productList) {
            if (id === this.superSaleObj.productList[i].id) {
              //isWish
              type === 0
                ? (this.superSaleObj.productList[i].isWish = 1)
                : (this.superSaleObj.productList[i].isWish = 0);
            }
          }
          for (let i in this.brandWeeklyList.productList) {
            if (id === this.brandWeeklyList.productList[i].id) {
              //isWish
              type === 0
                ? (this.brandWeeklyList.productList[i].isWish = 1)
                : (this.brandWeeklyList.productList[i].isWish = 0);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 订阅消息
     * @returns {Promise<void>}
     */
    async onAccountSubscribe() {
      try {
        this.loadingStatus = true;
        let params = {
          email: this.account,
          home: subscribeType[3].checkBox,
          kids: subscribeType[2].checkBox,
          men: subscribeType[1].checkBox,
          women: subscribeType[0].checkBox
        };
        const { code } = await userAccountSubscribeService(params);
        if (code === 1) {
          this.subscribeStatus = true;
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    this.SET_OFFSET_TOP(
      document.documentElement.scrollTop || document.body.scrollTop
    );
    $event.$off([EVENT_NAME.HOME_PAGE_CLASSIFY]);
    document.removeEventListener("mousewheel", this.handleScroll, false);
    document.removeEventListener("wheel", this.handleScroll, false);
  }
};
export default HomePage;

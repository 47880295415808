<template>
  <div class="layout-carousel">
    <a
      v-if="!isPhone()"
      href="javascript:"
      class="carousel-btn"
      :class="scroll === 0 ? 'carousel-grey-l' : 'carousel-black-l'"
      @click="onSwitchLeft"
    ></a>
    <div class="carousel-list" id="carouselId" @scroll="carouselScroll">
      <ul
        v-if="data.productList"
        :style="
          isPhone()
            ? 'width:' + (data.productList.length * 5.3 + 7) + 'rem'
            : 'width:' + ((data.productList.length + 2) * 245 - 25) + 'px'
        "
      >
        <li class="carousel-card">
          <div class="box">
            <span class="box-n">{{ data.number }}</span>
            <p class="box-c" v-html="data.beginning"></p>
          </div>
        </li>
        <li :key="key" v-for="(carousel, key) in data.productList">
          <div class="list-handle">
            <a
              href="javascript:"
              class="handle-unified like"
              @click="onLike(carousel)"
              :class="carousel.isWish === 1 ? 'like' : 'like-grey'"
            ></a>
            <a
              href="javascript:"
              class="handle-unified star"
              v-if="carousel.isStar === 1 || isPhone()"
              :class="carousel.isStar === 1 && isPhone() ? 'star' : 'star-grey'"
            ></a>
            <a
              href="javascript:"
              class="handle-unified draft"
              v-if="carousel.isSole === 1 || isPhone()"
              :class="carousel.isSole === 1 && isPhone() ? 'draft' : 'draft-grey'"
            ></a>
          </div>
          <img
            class="carousel-image"
            @click="onLinks(carousel.id)"
            v-lazy="carousel.images"
          />
          <div class="carouse-text">
            <p class="carouse-content brand">{{ carousel.brandName }}</p>
            <p class="carouse-content">
              {{
                currencyCode === "cny"
                  ? onCharacterPrice(carousel.price)
                  : carousel.price
              }}
            </p>
          </div>
        </li>
        <li class="carousel-card card-right" @click="onLinksList(data)">
          <div class="box">
            <div class="box-n more">{{ data.more }}</div>
            <p class="box-c" v-html="data.ending"></p>
          </div>
        </li>
      </ul>
    </div>
    <a
      href="javascript:"
      class="carousel-btn"
      v-if="!isPhone() && data.productList && data.productList.length !== 0"
      :class="
        scroll >= (data.productList.length + 2) * 245 - 25 - offsetWidth
          ? 'carousel-grey-r'
          : 'carousel-black-r'
      "
      @click="onSwitchRight"
    ></a>
  </div>
</template>
<script>
import Carousel from "./carousel";
export default Carousel;
</script>
<style lang="less">
@import "carousel";
</style>

<template>
  <div class="banner-carousel">
      <transition-group name="list" tag="ul" ref="container">
          <li v-for="(item, key) in data" :key="key" class="list-item"
              v-show="key+1 === currentIndex"
              @click="onImageText(item)"
              :style="{backgroundImage: `url(${item.coverImage})`}"
          >
              <div class="list-title-box">
                  <h1 class="main-title" :style="'font-size:'+item.ctSize+'px;line-height:'+item.ctLeading+'px;text-align:'+item.ctLocation+';color:#'+item.ctColour">{{ item.coverTitle }}</h1>
                  <h2 class="vice-title" :style="'font-size:'+item.csSize+'px;line-height:'+item.csLeading+'px;text-align:'+item.csLocation+';color:#'+item.csColour">- {{ item.coverSubtitle }} -</h2>
              </div>
        </li>
      </transition-group>
      <!-- s: circles -->
      <div class="circles-items">
          <a href="javascript:" class="circles-l circles-arrow" @click="onSwitchLeft()"></a>
          <a class="circles-n" :key="index" v-for="index in data.length" :class="{'active':index===currentIndex}"></a>
          <a href="javascript:" class="circles-r circles-arrow" @click="onSwitchRight()"></a>
      </div>
      <!-- e: circles -->
  </div>
</template>
<script>
import Banner from './banner'
export default Banner
</script>
<style lang="less">
@import "banner";
</style>

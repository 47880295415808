import { SOURCE_NAME } from "@/service/public/enum";
import { mapMutations, mapState } from "vuex";

/**
 * banner组件
 * @type {{data(): {}}}
 */
const Banner = {
    name:'Banner',
    props:{
        /**
         * 接收的数据
         */
        data:{
            type:Array,
            default:()=>[],
        }
    },
    computed:{
        ...mapState({
            menuType: state => state.product.menuType, //获取状态管理菜单分类
        }),
    },
    data(){
        return {
            currentIndex: 1,// 当前索引
            timer: '', //定时器对象
            switchingTime:6000, //当前切换的毫秒数
        }
    },
    mounted() {
        this.onTimer()
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID','SET_LABEL','SET_MENU_ID','SET_SELECTION_ID','SET_SOURCE','SET_SECOND_TYPE','SET_TERTIARY_TYPE']),
        /**
         * 跳转图文
         * @param obj
         */
        onImageText(obj){
            let secondId = ''
            let secondName = ''
            let tertiaryId = ''
            let tertiaryName = ''
            let label = ''
            let menuType = ''
            if(obj.contentType ===3){
                this.SET_SELECTION_ID(obj.selectionId)
                this.SET_MENU_ID(this.menuType)
                menuType = this.menuType
                this.SET_SOURCE(SOURCE_NAME.SUPER_SALE)
                this.SET_SECOND_TYPE({
                    categoryId:'',
                    name:''
                })
                secondId = ''
                secondName = ''
                this.SET_TERTIARY_TYPE({
                    categoryId:'',
                    name:'',
                })
                tertiaryId = ''
                tertiaryName = ''
                this.SET_LABEL(obj.coverTitle)
                label = obj.coverTitle
                this.$router.push('/product/list?menuType='+menuType+'&secondId='+secondId+'&secondName='+secondName+'&tertiaryId='+tertiaryId+'&tertiaryName='+tertiaryName+'&label='+label+'&page='+1)
            }else{
                this.SET_PRODUCT_ID(obj.contentId)
                this.$router.push('/image/text')
            }
        },
        /**
         * 计时器
         */
        onTimer() {
            this.timer = setInterval(() => {
                this.autoPlay()
            }, this.switchingTime)
        },
        /**
         * 点击左侧箭头切换
         */
        onSwitchLeft(){
            this.currentIndex--
            if(this.currentIndex < 1){
                this.currentIndex = this.data.length
            }
            clearInterval(this.timer)
            this.onTimer()
        },
        /**
         * 点击右侧箭头切换
         */
        onSwitchRight(){
            this.currentIndex++
            if(this.currentIndex > this.data.length){
                this.currentIndex = 1
            }
            clearInterval(this.timer)
            this.onTimer()
        },
        /**
         * 自动切换
         */
        autoPlay() {
            this.currentIndex++;
            if (this.currentIndex > this.data.length) {
                this.currentIndex = 1
            }
        },
    }
}
export default Banner

/**
 * 地图注册轮播图
 * @type {{data(): {}}}
 */
import Vue from "vue";
import { mapMutations } from "vuex";
import { Swipe, SwipeItem, Lazyload } from "vant";
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
import Cookies from "js-cookie";
import { isPhone } from "@/utils/tools";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { LINKS_NAME } from "@/service/public/enum";
const SignupRotation = {
  name: "SignupRotation",
  props: {
    /**
     * 接收的数据
     */
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: 1, // 当前索引
      timer: "", //定时器对象
      switchingTime: 6000, //当前切换的毫秒数
      LINKS_NAME: LINKS_NAME,
      isPhone: isPhone
    };
  },
  mounted() {
    this.onTimer();
  },
  methods: {
    ...mapMutations("userCenter", ["SET_SELECT_MENU"]),
    onRegister() {
      !Cookies.get("token")
        ? this.isPhone()
          ? $event.$emit(EVENT_NAME.LINKS_LOGIN_REGISTER, "create")
          : $event.$emit(EVENT_NAME.OPEN_LOGON, "Register")
        : this.isPhone()
        ? this.SET_SELECT_MENU(LINKS_NAME.USER_MENU)
        : this.$router.push("/user/center");
    },
    onSignIn() {
      !Cookies.get("token")
        ? this.isPhone()
          ? $event.$emit(EVENT_NAME.LINKS_LOGIN_REGISTER, "create")
          : $event.$emit(EVENT_NAME.OPEN_LOGON, "Register")
        : this.isPhone()
        ? this.SET_SELECT_MENU(LINKS_NAME.USER_MENU)
        : this.$router.push("/user/center");
    },
    /**
     * 计时器
     */
    onTimer() {
      this.timer = setInterval(() => {
        this.autoPlay();
      }, this.switchingTime);
    },
    /**
     * 点击左侧箭头切换
     */
    onSwitchLeft() {
      this.currentIndex--;
      if (this.currentIndex < 1) {
        this.currentIndex = this.data.length;
      }
      clearInterval(this.timer);
      this.onTimer();
    },
    /**
     * 点击右侧箭头切换
     */
    onSwitchRight() {
      this.currentIndex++;
      if (this.currentIndex > this.data.length) {
        this.currentIndex = 1;
      }
      clearInterval(this.timer);
      this.onTimer();
    },
    /**
     * 自动切换
     */
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.data.length) {
        this.currentIndex = 1;
      }
    }
  }
};
export default SignupRotation;

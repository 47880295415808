/**
 * 轮播图组件
 * @type {{data(): {}}}
 */
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { onCharacterPrice, isPhone } from "@/utils/tools";
import { mapState } from "vuex";
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
const Carousel = {
    name:'Carousel',
    props:{
        /**
         * 接收的数据
         */
        data:{
            type:Object,
            default:()=>[],
        },
    },
    computed:{
        ...mapState({
            currencyCode: state => state.system.currencyCode, //获取状态管理币种代码
        }),
    },
    data(){
        return {
            onCharacterPrice:onCharacterPrice,
            currentIndex: 0,// 当前索引
            currentSpace:0,
            number:5, //显示的条数
            scroll:0,
            offsetWidth:0,
            isPhone: isPhone
        }
    },
    mounted() {
        this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)+1
        /**
         * carousel宽
         */
        $event.$on(EVENT_NAME.CAROUSEL_OFFSET_WIDTH, () => {
            this.offsetWidth = Math.ceil(document.getElementById('carouselId').offsetWidth)+1
        });
    },
    methods: {
        /**
         * 监听超卖滚动条
         */
        carouselScroll(e) {
            this.scroll = Math.ceil(e.srcElement.scrollLeft)
        },
        /**
         * 点击左侧箭头切换
         */
        onSwitchLeft(){
            if(this.scroll >= 0){
                document.getElementById('carouselId').scrollLeft -= this.offsetWidth
            }else{
                document.getElementById('carouselId').scrollLeft =  0
            }
            this.scroll = document.getElementById('carouselId').scrollLeft
        },
        /**
         * 点击右侧箭头切换
         */
        onSwitchRight(){
            if(this.scroll <= ((this.data.productList.length+2)*245-25)-this.offsetWidth){
                document.getElementById('carouselId').scrollLeft += this.offsetWidth
            }else{
                document.getElementById('carouselId').scrollLeft =  ((this.data.productList.length+2)*245-25)-this.offsetWidth
            }
            this.scroll = document.getElementById('carouselId').scrollLeft
        },
        /**
         * 点击产品跳转
         */
        onLinks(id){
            this.$emit('on-links',id)
        },
        /**
         * 点击喜爱回调
         * @param obj
         */
        onLike(obj){
            this.$emit('on-like',obj)
        },
        /**
         * 点击更多跳转列表回调
         * @param obj
         */
        onLinksList(obj){
            this.$emit('on-links-list',obj)
        }
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.CAROUSEL_OFFSET_WIDTH])
    }
}
export default Carousel
